import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Context} from '../../../context.js';

import './welcome.css';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            sets: [],
            pending_sets: [],
            response: ""
        }
    }
    
    static contextType = Context;

    componentDidMount = () => {
        console.log("version 1.0.9");
        fetch(this.context[5]+"/all_sets")
        .then(res => res.json())
        .then((body) => (body.response_msg === "posted" ? this.setSets( body.returned_sets ): console.log("This is what happened:: "+JSON.stringify(body)) ))
        .catch((error) => console.log(error+"Error: unable to add category!"))
    }

    setSets = (sets) => {
        let published_sets = sets.filter(elem => elem.publish_set === "yes" );
        this.setState({ sets: published_sets });
        let pending_sets = sets.filter(elem => elem.publish_set === "pending" );
        this.setState({ pending_sets });
    }


    render() { 
        return ( 
            <div className="body_container">
                <div className="body_slides">
                    <figure>
                        <img src={process.env.PUBLIC_URL+"/slides/1.jpg"}  alt="flashcards srudying" />
                        <img src={process.env.PUBLIC_URL+"/slides/2.jpg"}  alt="flashcards srudying" />
                        <img src={process.env.PUBLIC_URL+"/slides/3.jpg"}  alt="flashcards srudying" />
                        <img src={process.env.PUBLIC_URL+"/slides/4.jpg"}  alt="flashcards srudying" />
                        <img src={process.env.PUBLIC_URL+"/slides/5.jpg"}  alt="flashcards srudying" />
                    </figure>
                </div>
                <div className="body_sets">
                    <div className="body_sets_heading_title">
                        Get complete sets of flashcards for the test
                    </div>
                    <div className="body_sets_heading_details">
                        Learn all you need for your tests and exams in less time.
                        Use the hundreds of quiz questions that accompany each flashcard set for improved results in the SAT and throughout High School.
                    </div>
                    <div className="body_sets_heading_title">
                        Take a look at our flashcard sets
                    </div>
                    { this.state.sets.map(( elem, index ) => (
                        <div key={"body_set"+index} className="set">
                            <Link key={"body_set_link"+index} to={{
                            pathname: "/starting",
                            state: { 
                                    title: elem.title,
                                    description: elem.description,
                                    image_url: elem.image_url,
                                    price: elem.price,
                                    set_id: elem.set_id,
                                    set: elem.title,
                                    category: elem.category 
                                }
                            }}>
                            <div key={"body_set_profile_img"+index} className="set_profile_img">
                                <img src={elem.image_url} alt={elem.title} />
                            </div>
                            <div key={"body_set_details"+index} className="set_details">
                                <div key={"body_set_title"+index} className="set_title">
                                    {elem.title.split(" ").splice(0, 8).join(" ")}
                                </div>
                                <div key={"body_set_description"+index} className="set_description">
                                    {elem.description.split(" ").splice(0, 16).join(" ")+"..."}
                                </div>
                                <div className="set_price">
                                    ${elem.price}
                                </div>
                            </div>
                            </Link>
                        </div>
                        ))}
                </div>
                <div className="body_description">
                    <div className="body_description_upcoming_courses">
                        Upcoming Courses
                    </div>
                    { this.state.pending_sets.map(( elem, index ) => (
                        <div key={"body_pending_set"+index} className="body_pending_set">
                            <div key={"body_pending_set_details"+index} className="body_pending_set_details">
                                <div key={"body_pending_set_number"+index} className="body_pending_set_number">
                                    {index+1}
                                </div>
                                <div key={"body_pending_set_title"+index} className="body_pending_set_title">
                                    {elem.title.split(" ").splice(0, 8).join(" ")}
                                </div>
                            </div>
                        </div>
                        ))}
                        <br /><br /><br /><br />
                    <div className="body_description_text">Use our carefully curated flashcards for quick revision to help you grasp concepts. They are designed to help you memorize the facts you need to know to ace your tests and exams.</div>
                </div>
            </div>
        );
    }
}
 
export default Welcome;