import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Context} from '../../../context.js';
import CheckOutForm from './checkoutform.js';
import CheckoutSavedCard from './checkoutsavedcard.js'
import './payments.css';

//const stripePromise = loadStripe("pk_test_5kJNmJ7ZYV3izEhUrHnOXZMt");
const stripePromise = loadStripe("pk_live_q6z7DnwZWw3CHsveSQPZiJdu");

class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title: "",
            Description: "",
            image_url: "",
            products: [{
                set_id: this.props.location.state.set_id,
                title: this.props.location.state.title,
                price: this.props.location.state.price
            }],
            customer_order_id: "",
            card_details: {},
            flashcard_sets_bought: [],
            id: "",
            email: "",
            move_to_confirm_order: false,
            go_to_signin: false
        }
    }

    static contextType = Context;

    clickStripe = () => {
        document.getElementsByClassName("stripe_btn")[0].click();
    }

    componentWillMount = () => {
        const email = localStorage.getItem("flashcards_stdtkto_email");       
    
        if( !email ){
            this.setState({ go_to_signin: true });
        }
    }

    componentDidMount = () => {
        const id = localStorage.getItem("flashcards_stdtkto_id");
        const email = localStorage.getItem("flashcards_stdtkto_email");       
    
        this.setState({ title: this.props.location.state.title });
        this.setState({ description: this.props.location.state.description });
        this.setState({ image_url: this.props.location.state.image_url });    
        
        this.setState({id});
        this.setState({email});
        axios.get(this.context[5]+"/myaccount?email="+email+"&id="+id)
        .then(body => (body.data.response_msg === "posted"? this.setAccountDetails(body.data): console.log("An error occured")))
        .then(() => {
            if( this.state.flashcard_sets_bought.length > 0 && this.state.flashcard_sets_bought.includes( this.props.location.state.set_id ) ){
                //flashcards does not include product
                this.setState({ should_begin: true });
            }else{
                this.setState({ should_begin: false });          
            }
        })
        .catch((error) => console.log(error+"Error: unable to add category!"))       

        this.setState({ set: this.props.location.state.set });

    }


    setAccountDetails = ( details ) => {
        if( details.customer_order_id !== undefined ){
            const card_details = {
                customer_order_id: details.customer_order_id,
                card: details.card,
                last_4_digits: details.last_4_digits,
                email: this.state.email,
                id: this.state.id
            }
            this.setState({ flashcard_sets_bought: details.flashcard_sets_bought });
            this.setState({ customer_order_id: details.customer_order_id });
            this.setState({ card_details });
        }
    }


    moveToConfirmOrder = (status) => {
        this.setState({ move_to_confirm_order: status });
    }


    render() { 
        if( this.state.go_to_signin ){
            return (
            <Redirect to={{
                pathname: "/signin"
            }}
            />
            )
        }else{
            if( this.state.move_to_confirm_order ){
                return (
                    <Redirect to={{
                        pathname: "/confirmation",
                        state: {
                            set_id: this.state.products[0].set_id,
                            title: this.state.products[0].title,
                            email: this.state.email
                        }
                    }}
                    />
                )
            }else{
                if( this.state.customer_order_id.length > 10 ){
                    return (
                    <div className="body_checkout">
                        <div className="body_checkout_heading">
                            Your order
                        </div>
                        <div className="body_checkout_orders">
                            <div className="body_checkout_order">
                                <div className="body_checkout_order_img">
                                    <img src={this.state.image_url} alt={this.state.image_url} />
                                </div>
                                <div className="body_checkout_order_details">
                                    <div className="body_checkout_order_title">
                                        {this.state.title}
                                    </div>
                                    <div className="body_checkout_order_tags">
                                        <div className="body_checkbox_order_tag">
                                            Flashcard Set
                                        </div>    
                                    </div>
                                </div>
                                <div className="body_checkout_order_amount">
                                    ${this.props.location.state.price}
                                </div>
                            </div>
                        </div>
                        <div className="body_checkout_confirm">
                        <Elements stripe={stripePromise}>
                            <CheckoutSavedCard orders={[this.state.products, this.state.card_details, this.moveToConfirmOrder]} />
                        </Elements> 
                        </div>
                    </div>
                    );
                }else{
                return ( 
                    <div className="body_checkout">
                        <div className="body_checkout_heading">
                            Your order
                        </div>
                        <div className="body_checkout_orders">
                            <div className="body_checkout_order">
                                <div className="body_checkout_order_img">
                                    <img src={this.state.image_url} alt={this.state.image_url} />
                                </div>
                                <div className="body_checkout_order_details">
                                    <div className="body_checkout_order_title">
                                        {this.state.title}
                                    </div>
                                    <div className="body_checkout_order_tags">
                                        <div className="body_checkbox_order_tag">
                                            Flashcard Set
                                        </div>    
                                    </div>
                                </div>
                                <div className="body_checkout_order_amount">
                                    ${this.props.location.state.price}
                                </div>
                            </div>
                        </div>
                        <div className="body_checkout_confirm">
                        <Elements stripe={stripePromise}>
                            <CheckOutForm orders={[this.state.products, this.moveToConfirmOrder]} />
                        </Elements> 
                        </div>
                    </div>
                    );
                }
            }
        }
    }
}
 
export default CheckOut;