import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {Context} from '../../../context.js';

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const context = useContext(Context);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById("body_checkout_confirm_btn").disabled = true;
    document.getElementsByClassName("body_checkout_confirm_btn")[0].style.opacity = "0.5";
    
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      stripeTokenHandler(result.token);
    }
  };

  // POST the token ID to your backend.
  async function stripeTokenHandler(token) {
    let email =  localStorage.getItem("flashcards_stdtkto_email");
    let product_email = email;
    let product_price = props.orders[0][0].price;
    let product_title = props.orders[0][0].title;
    let product_id = localStorage.getItem("flashcards_stdtkto_id");
    let product_set_id = props.orders[0][0].set_id;
    let token_id = token.id;
    let token_email = email;
    let token_card_last4 = token.card.last4;
    let token_card_brand = token.card.brand;
            
    await axios.get(context[5]+"/checkout?product_email="+product_email+"&product_price="+product_price+"&product_title="+product_title+"&product_id="+product_id+"&product_set_id="+product_set_id+"&token_id="+token_id+"&token_email="+token_email+"&token_card_last4="+token_card_last4+"&token_card_brand="+token_card_brand)
    .then(body => {
      if(body.data === "success" ){
        (async () => {
        await axios.get(context[5]+"/order_confirmation_email?email="+product_email+"&title="+product_title )
        .then(body => {
          if(body.data.response_msg === "success" ){   
            props.orders[1](true);
          }else{
            props.orders[1](true);
          }
        });
        })()
      }
    })
    .catch(e => console.log(e));
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row"><br />
        <label htmlFor="card-element">
            Payment Details
        </label><br /><br />
        <div className="body_checkout_card_element">
            <CardElement
            id="card-element"
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
            />
        </div>
        <div className="card-errors" role="alert">{error}</div>
      </div>
      <div className="body_checkout_confirm">
        <button id="body_checkout_confirm_btn" type="submit">
            <div className="body_checkout_confirm_btn">
                Confirm Order
            </div>
        </button>
      </div>
    </form>
  );
}


export default CheckoutForm;