import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../../../context.js'

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            sets: []
        }
    }

    static contextType = Context;

    componentDidMount = () => {
        if( this.props.location.state.title.length > 5 ){
            this.removeFromCart();
        }

        fetch(this.context[5]+"/all_sets")
            .then(res => res.json())
            .then((body) => (body.response_msg === "posted" ? this.setSets( body.returned_sets ): this.setState({ response: "Could not scan categories table!" }) ))
            .catch((error) => console.log(error+"Error: unable to add category!"))
    }

    setSets = (sets) => {
        sets = sets.filter( elem => elem.publish_set === "yes" );
        this.setState({ sets: sets });
        
        console.log("we've got: "+this.state.flashcard_sets_bought);
        fetch(this.context[5]+"/purchased_sets?flashcard_sets_bought="+this.state.flashcard_sets_bought)
        .then(res => res.json())
        .then((body) => (body.response_msg === "posted" ? this.setState({ purchased_sets: body.returned_sets }, () => (body.returned_sets.length === 0) ? this.setState({ purchased_sets_msg: "You currently have no flashcards!" }): this.setState({ purchased_sets_msg: "Your flashcard sets" })): this.setState({ response: "Could not scan categories table!" }) ))
        .catch((error) => console.log(error+"Error: unable to add category!"))
    }


    removeFromCart = () => {
        let email = localStorage.getItem("flashcards_stdtkto_email");
        let id = localStorage.getItem("flashcards_stdtkto_id");
        let set_to_delete_from_cart = this.props.location.state.set_id;
        
        if( localStorage.getItem("flashcards_stdtkto_email").length > 5 ){
            axios.get(this.context[5]+"/update_cart?email="+email+"&id="+id+"&set_id="+set_to_delete_from_cart)
            .then(body => (body.data.response_msg === "posted" ? this.refreshCart() : body.data.response_msg === "" ))
            .catch(e => console.log(e));
        }else{
            window.location = "/signin"
        }
    }


    refreshCart = () => {
        let email = localStorage.getItem("flashcards_stdtkto_email");       
        let id = localStorage.getItem("flashcards_stdtkto_id");
        if( !email || !id ){
            email = id = "";
        }
        fetch(this.context[5]+"/sets_id_cart?email="+email+"&id="+id)
        .then(res => res.json())
        .then(body => (body ? (body.response_msg === "posted" ? this.setCart(body) : console.log( "could not refresh cart" )): console.log("returned null "+body)))
        .catch(e => console.log(e));
    }


    setCart = (sets) => {
        localStorage.setItem("flashcards_stdtkto_sets_in_cart", JSON.stringify(sets.returned_sets[0].cart));
        if( sets.returned_sets[0].cart ){
            this.context[3](sets.returned_sets[0].cart.length);
        }else{
            this.context[3](0);
        }
    }
    
    updateCart = () => {
        //currently unneeded because the window is refreshed:::: window.location ="/confirmation" 
        const cart = this.context[4] - 1;
        this.context[3](cart);
    }


    render() { 
        return ( 
            <div className="body_confirmation">
                <h2>
                    Congratulations! Your order was successful.
                </h2>
                <p>A confirmation of your order, <b>{this.props.location.state.title}</b> was sent to your email.</p>
                <br />
                <h2>
                    Recommendations
                </h2>
                <div className="body_confirmation_recommendation_sets">
                    { this.state.sets.map(( elem, index ) => (
                        <div key={"body_set"+index} className="set">
                            <Link key={"body_set_link"+index} to={{
                            pathname: "/starting",
                            state: { 
                                    title: elem.title,
                                    description: elem.description,
                                    image_url: elem.image_url,
                                    price: elem.price,
                                    set_id: elem.set_id,
                                    set: elem.title 
                                }
                            }}><div key={"body_set_profile_img"+index} className="set_profile_img">
                                <img src={elem.image_url} alt={elem.title} />
                            </div>
                            <div key={"body_set_details"+index} className="set_details">
                                <div key={"body_set_title"+index} className="set_title">
                                    {elem.title.split(" ").splice(0, 8).join(" ")}
                                </div>
                                <div key={"body_set_description"+index} className="set_description">
                                    {elem.description.split(" ").splice(0, 16).join(" ")+"..."}
                                </div>
                                <div className="set_price">
                                    ${elem.price}
                                </div>
                            </div>
                            </Link>
                        </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

 
export default Confirmation;