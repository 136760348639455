import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
    useStripe
} from '@stripe/react-stripe-js';
import {Context} from '../../../context.js';

// Custom styling can be passed to options when creating an Element.

const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const context = useContext(Context);

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById("body_checkout_confirm_btn").disabled = true;
    document.getElementsByClassName("body_checkout_confirm_btn")[0].style.opacity = "0.5";
    
    const email = props.orders[1].email;
    const id = props.orders[1].id;
    const result = await stripe.createToken('person', {
        first_name: email,
        last_name: id,
        relationship: {owner: true},
      })
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      stripeTokenHandler(result.token);
    }
  };

  // POST the token ID to your backend.
  async function stripeTokenHandler(token) {
    let email =  localStorage.getItem("flashcards_stdtkto_email");
    let product_email = email;   
    let product_price = props.orders[0][0].price;
    let product_title = props.orders[0][0].title;
    let product_id = localStorage.getItem("flashcards_stdtkto_id");
    let product_set_id = props.orders[0][0].set_id;
    let customer_order_id = props.orders[1].customer_order_id;
    let token_email = email;
       
    await axios.get(context[5]+"/checkout_with_customer_id?product_email="+product_email+"&product_price="+product_price+"&product_title="+product_title+"&product_id="+product_id+"&product_set_id="+product_set_id+"&customer_order_id="+customer_order_id+"&token_email="+token_email)
    .then(body => {
      if(body.data === "success" ){
        (async () => {
        await axios.get(context[5]+"/order_confirmation_email?email="+email+"&title="+product_title )
        .then(body => {
          if(body.data.response_msg === "success" ){   
            props.orders[2](true);
          }else{
            props.orders[2](true);
          }
        })
        .catch(e => console.log(e));
        })()
      }
    })
    .catch(e => console.log(e));
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row"><br />
        <label htmlFor="card-element">
            Payment Details
        </label><br /><br />
        <div className="body_checkout_card_element">
          {props.orders[1].card} {props.orders[1].last_4_digits}
        </div>
        <div className="card-errors" role="alert">{error}</div>
      </div>
      <div className="body_checkout_confirm">
        <button id="body_checkout_confirm_btn" type="submit">
            <div className="body_checkout_confirm_btn">
                Confirm Order
            </div>
        </button>
      </div>
    </form>
  );
}


export default CheckoutForm;