import React, { Component } from 'react';
import "./termsprivacy.css";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            sets: []
        }
    }


    render() { 
        return ( 
            <div className="body_termsprivacy">
                <h2>
                    Terms of Use ("Terms")
                </h2>
                <p>
                    Last updated: (03.21.2020)<br /><br />
                    Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the
                    http://www.tracsalprep.com website ("Service") operated by Tracsal Prep.
                    Your access to and use of the Tracsal Prep is conditioned on your acceptance of and compliance with
                    these Terms. These Terms apply to all visitors, users and others who access or use the Service.
                    By accessing or using the Service you agree to be bound by these Terms. If you disagree
                    with any part of the terms then you may not access or use the Service.<br />
                </p>
                <h3>Purchases</h3>
                <p>
                    If you wish to purchase any product or service made available through the Service ("Purchase"),
                    you may be asked to supply certain information relevant to your Purchase including, without
                    limitation, your credit or debit card details and other personal information.
                </p>
                <h3>Termination</h3>
                <p>
                    We may terminate or suspend access to our Service immediately, without prior notice or liability, for
                    any reason whatsoever, including without limitation if you breach the Terms.<br />
                    All provisions of the Terms which by their nature should survive termination shall survive
                    termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
                    limitations of liability.<br />
                </p>
                <h3>Content</h3>
                <p>
                    Our Service may allow you to post, link, store, share and otherwise make available certain information,
                    text, graphics, videos, or other material ("Content"). You are responsible for the Content you post and 
                    accept liability resulting from the post of the Content.<br />
                    Our Service may contain links to third-party web sites or services that are not owned or controlled
                    by Tracsal Prep.<br />
                    Tracsal Prep has no control over, and assumes no responsibility for, the content,
                    privacy policies, or practices of any third party web sites or services. You further acknowledge and
                    agree that Tracsal Prep shall not be responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in connection with use of or reliance on any
                    such content, goods or services available on or through any such web sites or services.
                </p>
                <h3>Changes</h3>
                <p>
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                    revision is material we will try to provide at least 14 days' notice prior to any new terms
                    taking effect. What constitutes a material change will be determined at Tracsal Prep's sole discretion.
                </p>    
                <h3>Contact Us</h3>
                <p>
                    If you have any questions about these Terms, please contact us.
                    Create your own Terms of Use
                    For websites, apps and more. Download as HTML or Text. Unlock premium content.
                    Disclaimer: Legal information is not legal advice. Read the disclaimer.
                </p>
            </div>
        )
    }
}

export default Terms;